<template>
    <a-modal
        :visible="dialog.show"
        :footer="false"
        @cancel="onClose"
        @ok="onClose"
        :width="'700px'"
        :bodyStyle="{padding: '40px'}"
        centered
        >

        <template>
            <h5>Add New</h5>
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                >
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item ref="name" label="Name" prop="name">
                            <a-input
                                v-model="form.name"
                                placeholder="Enter form name"
                                size="large"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                            <div class="mr-2">Type</div>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Select either a form or a survey or a worksheet.
                                </template>
                                <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                            </a-tooltip>
                        </div>
                        <a-form-item>
                            <a-select :default-value="form.type" @change="selectType" size="large">
                                <a-select-option value="form">
                                    Web Form
                                </a-select-option>
                                <a-select-option value="survey">
                                    Survey
                                </a-select-option>
								<a-select-option value="worksheet" v-if="$store.getters.isPremiumPackage">
                                    Worksheet
                                </a-select-option>
                                <!-- <a-select-option value="walk-in">
                                    Walk-in Form
                                </a-select-option> -->
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <!-- <a-row>
                    <a-form-model-item ref="url" label="URL" prop="url">
                        <a-input placeholder="i.e. Registration" addon-before="https://bildhive.net/forms/" size="large" v-model="form.url" />
                    </a-form-model-item>
                </a-row> -->
            </a-form-model>

            <div class="mt-3" style="width: 100%; text-align: right;">
                <a-button @click="onClose" class="mr-3 cancel-button" size="large">CANCEL</a-button>
                <a-button @click="submit" type="primary" size="large">CREATE</a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
  import bhLoading from 'bh-mod/components/common/Loading'
  import Expander from 'bh-mod/components/common/Expander'
  export default {
    components:{bhLoading,Expander},

    data() {
      return {
        loading:false,
        form:{
            leadScore: 1,
            name:'',
            type:'form',
            url:'',
            instance:this.$store.state.instance.id,
            tags:[],
            fields:[
                {
                    type:'email',
                    id:Date.now() + '',
                    label:'Email',
                    placeholder:'',
                    required:true
                },
                {
                    id:Date.now() + 1,
                    hide:false,
                    type:'name',
                    label:'Name',
                    required:false,
                    middleInitial:false,
                    prefix:false,
                    suffix:false,
                    icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mr-2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>`
                },
                {
                    id:Date.now() + 2,
                    hide:false,
                    type:'phone',
                    label:'Phone Number',
                    placeholder:'',
                    required:false,
                    format:'National',
                    country:'',
                    default:'',
                    icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>`
                },
                {
                    id:Date.now() + 3,
                    hide:false,
                    type:'address',
                    label:'Address',
                    required:false,
                    country:'',
                    options:{
                        addressOne:true,
                        addressTwo:true,
                        city:true,
                        province:true,
                        postal:true,
                        country:false
                    },
                    icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
                },
                {
                    id:Date.now() + 4,
                    hide:false,
                    type:'isAgent',
                    displayType:'checkbox',
                    label:'Are you a realtor?',
                    required:false,
                    default:'',
                    options:[
                        {option:1},
                        {option:0}
                    ],
                    icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
                }
            ],
            lastRegistered:0,
            users:[],
            options:{
                feedChildren:true,
                submitLabel:'Submit',
                thanks:{
                    headline:'',
                    text:''
                },
                supporting:'',
                consent:{
                    enabled:true,
                    text:'I consent.'
                },
				smsConsent:{
                    enabled:false,
                    text:'By submitting this form and signing up for texts, you consent to receive marketing text messages from [company name] at the number provided. Consent is not a condition of purchase. Msg & data rates may apply.'
                },
                autoresponder:'',
                autoresponderSubject:'',
				autoresponderPreheader:'',
                fromEmail:''
            }
        },
        rules: {
            name: [
                { required: true, message: 'Please input a name', trigger: 'blur' },
            ],
            url: [
                { required: true, message: 'Please enter a URL', trigger: 'blur' }
            ],
        },
      }
    },
    watch:{
      dialog:{
        handler(val){
            if (!val.show){

            }
        },
        deep:true
      },
    },
    computed:{
        instance(){
          return this.$store.state.instance
        },
        dialog(){
          return this.$store.state.forms.formDrawer
        },
    },
    methods: {
        submit(){
            console.log('SUBMIT CLICKED')
            let form = JSON.parse(JSON.stringify(this.form))
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.$store.commit('OPEN_FORM_PAGE', form)
                    this.$router.push('/add')
                    this.onClose()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        selectType(e){
            console.log('SELECTED',e)
            this.form.type = e
        },
        onClose() {
            this.$store.commit('CLOSE_DRAWERS')
            this.$refs.ruleForm.resetFields();
            this.form = {
                leadScore: 1,
                name:'',
                type:this.form.type,
                url:'',
                instance:this.$store.state.instance.id,
                tags:[],
                fields:[
                    {
                        type:'email',
                        id:Date.now() + '',
                        label:'Email',
                        placeholder:'',
                        required:true
                    },
                    {
                        id:Date.now() + 1,
                        hide:false,
                        type:'name',
                        label:'Name',
                        required:false,
                        middleInitial:false,
                        prefix:false,
                        suffix:false,
                        icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mr-2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>`
                    },
                    {
                        id:Date.now() + 2,
                        hide:false,
                        type:'phone',
                        label:'Phone Number',
                        placeholder:'',
                        required:false,
                        format:'National',
                        country:'',
                        default:'',
                        icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>`
                    },
                    {
                        id:Date.now() + 3,
                        hide:false,
                        type:'address',
                        label:'Address',
                        required:false,
                        country:'',
                        options:{
                            addressOne:true,
                            addressTwo:true,
                            city:true,
                            province:true,
                            postal:true,
                            country:false
                        },
                        icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
                    },
                    {
                        id:Date.now() + 4,
                        hide:false,
                        type:'isAgent',
                        displayType:'checkbox',
                        label:'Are you a realtor?',
                        required:false,
                        default:'',
                        options:[
                            {option:1},
                            {option:0}
                        ],
                        icon:`<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
                    }
                ],
                lastRegistered:0,
                users:[],
                options:{
                    feedChildren:true,
                    submitLabel:'Submit',
                    thanks:{
                        headline:'',
                        text:''
                    },
                    supporting:'',
                    consent:{
                        enabled:true,
                        text:'I consent.'
                    },
					smsConsent:{
                        enabled:false,
                        text:'By submitting this form and signing up for texts, you consent to receive marketing text messages from [company name] at the number provided. Consent is not a condition of purchase. Msg & data rates may apply.'
                    },
                    autoresponder:'',
                    autoresponderSubject:'',
					autoresponderPreheader:'',
                    fromEmail:''
                }
            }
        },
    },
  };
</script>

<style>
.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>

<style lang="scss" scoped>

</style>
<style lang="scss">
    .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
        background:#3F3356;
    }
</style>
